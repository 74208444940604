
import React, { Component } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import LogoPvg from "../../assets/bluwave-logo.png";


const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const RenderEnodeIcon = (props) => (
  <svg {...props} class="_1f4lbxf7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" fill="currentColor" height="43" width="70"><path d="M14.4 9.89c.774 0 1.353.3 1.74.75a8.112 8.112 0 0 0-16.133.054c-.025.253.016.508.121.74a1.779 1.779 0 0 0 3.275-.053.93.93 0 0 0 .062-.29c0-.013 0-.027.003-.04a4.631 4.631 0 0 1 9.161-.367c.386-.472.975-.794 1.772-.794Z"></path><path d="M14.578 10.695c-.793-.083-1.554.404-1.843 1.12a.487.487 0 0 0-.03.132 4.63 4.63 0 0 1-9.13.5c-.379.544-1.004.923-1.871.923-.695 0-1.231-.244-1.614-.62a8.11 8.11 0 0 0 16.065-.351.477.477 0 0 0 0-.104c-.096-.81-.752-1.516-1.577-1.6Z"></path><path d="M8.224 13.307a1.883 1.883 0 1 0 0-3.766 1.883 1.883 0 0 0 0 3.766ZM31.838"></path></svg>
);

const enodeIconStyle = {color:"hsl(93, 60%, 37%)", cursor:"pointer", verticalAlign:"middle", marginLeft:"20px", width:"100px"}


const StopCharging = (props) => {
  let {SOC, car, onSubmit, waiting, error} = props;

  const iconPowerStyle = {fontSize:'30px', verticalAlign:"middle", color: "#00AA00"}
  return (
    <div className="box">
        <div style={{justifyContent:"space-between", display:"flex", lineHeight:"56px", fontSize:"18px", color:"#00AA00"}}>
            <HomeIcon/>
            <RenderLogo width="160" />
            <Menu/>
        </div>
        <div className="field has-text-centered">
           <br /><div><RenderEnodeIcon style={iconPowerStyle}/></div><br />
          <div>Your</div>
          <div><b>{car ? (car.brand + " " + car.model + " " + car.year) : "Car not found"}</b></div>
          <div>is charging with a </div>
          <div><b>SoC of {SOC} %</b></div>
          <br />
          {error && <div>{error}</div>}
          <button
            className={`button is-primary`}
            onClick={onSubmit}
            disabled={waiting}
            style = {{backgroundColor:'#FB8500'}}
          >
            {waiting ? "Pausing Charging..." : "Pause Charging"}
          </button>
          <br />
          <br />
        </div>
    </div>
  );
}

export default StopCharging