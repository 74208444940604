
import React, { Component, useState } from "react";
import { Field, reduxForm } from "redux-form";
import "./Loginform.scss";
import LogoPvg from "../../assets/bluwave-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import {BiShow} from "react-icons/bi";


const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username Required";
    }
  
    if (!values.password) {
      errors.password = "Password Required";
    }
  
    return errors;
  };
  
  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
  }) => (
    <div>
      <input {...input} placeholder={label} type={type} className="input" />
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-danger">{warning}</p>))}
    </div>
  );


  const RenderLogo = (props) => (
    <img {...props} alt="Bluewave Logo" src={LogoPvg} />
  );

  const RegisterButton = () => {
    window.location.href = "#/register";
    };
  
  const LoginForm = (props) => {

    let { errors, handleSubmit, pristine, submitting, loading } = props;
    const onSubmit = (formValues) => {
      props.onSubmit(formValues);
    };
    
    let [displayPassword, setDisplayPassword] = useState(false);

    const ToggleDisplayPassword = () => {
      setDisplayPassword(!displayPassword);
    }

    return (
      <form className="box" onSubmit={handleSubmit(onSubmit)}>
        <div className="field has-text-centered">
          <br />
          <RenderLogo width="160" />
          <br />
        </div>
        <div className="field has-text-centered">
        <span style={{color:"#FB8500"}}><b>EV Flight Test Centre</b></span>
        </div>
        <div className="field input--add_extra_margin">
          <label className="label">Email</label>
          <div className="control has-icons-left">
            <Field
              name="email"
              type="email"
              component={renderField}
              label="e.g. user@bluwave-ai.com"
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
          </div>
        </div>
        <div className="field input--add_extra_margin">
          <label className="label">Password</label>
          <div className="control has-icons-left has-icons-right">
            <Field
              type={displayPassword ? "text" : "password"}
              name="password"
              component={renderField}
              label="Password"
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <span className="icon is-small is-right" style={{cursor:"pointer", zIndex:"100", pointerEvents:"auto"}} onClick={ToggleDisplayPassword}>
              <BiShow style={{fill:"#000"}}/>
            </span>
          </div>
        </div>

        <div className="field has-text-centered">
          <p className="help is-danger">{errors}</p>
          <br />
          <button
            className={`button is-primary`}
            disabled={submitting}
            onClick={RegisterButton}
            type={"button"}
          >
            Sign up
          </button>
          <button
            className={`button is-primary ${loading ? `is-loading` : ``}`}
            disabled={pristine || submitting}
            style={{marginLeft:"20px"}}
          >
            Login
          </button>
          <div style={{marginTop:"20px"}}>
            <a href="#/forgotpassword">
              Reset Password           
            </a>
          </div>
          <br />
          <br />
        </div>
      </form>
    );
  }
  
  export default reduxForm({
    form: "login",
    validate,
  })(LoginForm);