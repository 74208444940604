
import { fontSize } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import { BsEvStation } from "react-icons/bs";
import { MdOutlineStar } from "react-icons/md";

const blupointstyle = {color:"#FB8500"};

const iconStyle = {fontSize:'30px', verticalAlign:"middle", marginLeft:"20px"}

const formatTime = (timeString) => {
    let split = timeString.split(" ")[1].split(":");
    let hours = parseInt(split[0], 10);
    let minutes = split[1];
    let ampm = "AM";
    if(hours >= 12) {
      hours -= 12;
      ampm = "PM";
    }
    if(hours == 0) hours = 12;
  
    return hours+":"+minutes+" "+ampm; 
}

const Index = ({transaction, cars, backButton}) => {

    let car = {}

    if(transaction) {
        car = cars.find(element => element.id === transaction.carID);
    }
    
    return (
        <>
            <div style={{textAlign:'center', marginLeft:"30%", marginTop: "10px", marginBottom: "10px", color:"#425498", fontWeight:"bold"}}>
                { transaction.bluscore <= 33 ?
                <div className={`bluscore_box bluscore_a`}>{transaction.bluscore}</div>
                : transaction.bluscore <= 66 ?
                <div className={`bluscore_box bluscore_b`}>{transaction.bluscore}</div>
                :
                <div className={`bluscore_box bluscore_c`}>{transaction.bluscore}</div>
                }
            </div>                       
            <div><BsEvStation  style={iconStyle}/> <b>{transaction.chargerID}</b></div>
            <br />
            <div><b>Car: </b>{car ? (car.brand + " " + car.model + " " + car.year) : "Car not found"}</div>
            <div><b>Start: </b>{formatTime(transaction.startedAt)}</div>
            <div><b>Stop: </b>{formatTime(transaction.finishedAt)}</div>
            <div><b>Energy charged: </b>{transaction.energyCharged?.toFixed(2)} kWh</div>
            <div><b>BluPoints: </b><MdOutlineStar style={blupointstyle}/> {transaction.blupoints}</div>
            <div><b>Utility Zone: </b>{transaction.region}</div>
            <br />
            {transaction.optedOut ? <div style={{color:"#2F5597", fontSize:"12px"}}>Opted-Out of Load Balancing</div> : 
            <>
            <div><b>Arrival SoC: </b>{transaction.arrivalSOC}</div>
            <div><b>Estimated SoC: </b>{transaction.estimatedSOC}</div>
            <div><b>Preferred Departure SoC: </b>{transaction.requestedSOC}</div>
            <div><b>Estimated Departure Time: </b>{formatTime(transaction.requestedDepartureTime)}</div>
            </>}
            <br />
            <button
            className={`button is-primary`}
            onClick={backButton}
            >
            Back
            </button>
        </>
    )
}

export default Index;