import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { reducer as formReducer } from "redux-form";
import timerMiddleware from "redux-timer";
import thunk from "redux-thunk";
import storage from "localforage";


const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["AuthStore", "deviceStore"],
};

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  form: formReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware, timerMiddleware)
);
const store = createStore(
  persistReducer(persistConfig, reducers),
  initialState,
  enhancer
);

export default store;

export const persistor = persistStore(store);
