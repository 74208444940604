import ChargerButton from "./chargerButton"

const Index = ({data, numResults}) => {
    return (
        <>
            <table style={{width:"100%"}} border="0">
                <tbody>
                {data?.map((row, index) => (
                    (numResults === undefined || numResults > index) &&<ChargerButton data={row} key={row.id}/>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default Index;