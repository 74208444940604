
import { BiHomeAlt2 } from "react-icons/bi";



const Index = () => {


  const OnClick = () => {
    window.location.href="#/dashboard";
};






  return (
      <BiHomeAlt2 onClick={OnClick} style={{color:"hsl(229, 53%, 53%)", cursor:"pointer", height:"36px", width:"36px"}}/>
  );
};

export default Index;