
import React, { Component } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import { useState, useEffect } from "react";
import { BsEvStation } from "react-icons/bs";
import { BsEvStationFill } from "react-icons/bs";
import { ImPower } from "react-icons/im";
import LogoPvg from "../../assets/bluwave-logo.png";


const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const formatTime = (timeString) => {
  if(timeString === undefined) return "";
  let split = timeString.split(" ")[1].split(":");
  let hours = parseInt(split[0], 10);
  let minutes = split[1];
  let ampm = "AM";
  if(hours >= 12) {
    hours -= 12;
    ampm = "PM";
  }
  if(hours == 0) hours = 12;

  return hours+":"+minutes+" "+ampm;

}
  
const StopTransaction = ({transactions, charger, onSubmit, waiting, cars, finished}) => {

  let [transaction, setTransaction] = useState({});
  let [car, setCar] = useState(false);


  
  useEffect(() => {
    const t = transactions.find((element) => {
      return (element.chargerID === charger.id) && !element.finishedAt
    });
    if(t === undefined) {

    } else {
      const c = cars.find((element) => {
        return element.id === t.carID
      });
      if(c !== undefined) {
        setCar(c);
      }

      setTransaction(t);
    }
  }, [transactions, charger, cars]);

  const iconStyle = {fontSize:'30px', verticalAlign:"middle", marginLeft:"20px"}
  const iconPowerStyle = {fontSize:'20px', verticalAlign:"middle", color: "#00AA00"}

  return (
    <div className="box">
        <div style={{justifyContent:"space-between", display:"flex", lineHeight:"56px", fontSize:"18px", color:"#00AA00"}}>
            <HomeIcon/>
            <RenderLogo width="160" />
            <Menu/>
        </div>
        <div style={{textAlign:"center", lineHeight:"56px", fontSize:"18px", color:"#00AA00"}} ><b>Active Charging Session</b></div>
        <div className="field has-text-centered">
        <div><ImPower style={iconPowerStyle}/></div>

        <div style={{textAlign:'center', marginLeft:"30%", marginTop: "10px", marginBottom: "10px", color:"#425498", fontWeight:"bold"}}>
                { transaction.bluscore <= 33 ?
                <div className={`bluscore_box bluscore_a`}>{transaction.bluscore}</div>
                : transaction.bluscore <= 66 ?
                <div className={`bluscore_box bluscore_b`}>{transaction.bluscore}</div>
                :
                <div className={`bluscore_box bluscore_c`}>{transaction.bluscore}</div>
                }
          </div>    
          {charger.private?
           <>
            <div><BsEvStationFill  style={iconStyle}/> <b>{charger.id}</b></div>
          </>
           :
           <>
            <div><BsEvStation  style={iconStyle}/> <b>{charger.id}</b></div>
          </>
          }
          <br />
          <div><b>Car: </b>{car ? (car.brand + " " + car.model + " " + car.year) : "Car not found"}</div>
          <div><b>Start: </b>{formatTime(transaction.startedAt)}</div>
          <div><b>Current Power: </b>{transaction.power?.toFixed(2)} kW</div>
          <div><b>Energy charged: </b>{transaction.energyCharged?.toFixed(2)} kWh</div>
          <div><b>Utility Zone: </b>{transaction.region}</div>
          <br />
          {transaction.optedOut ? <div style={{color:"#2F5597", fontSize:"12px"}}>Opted-Out of Load Balancing</div> : 
          <>
            <div><b>Arrival SoC: </b>{transaction.arrivalSOC}</div>
            <div><b>Estimated SoC: </b>{transaction.estimatedSOC}</div>
            <div><b>Preferred Departure SoC: </b>{transaction.requestedSOC}</div>
            <div><b>Estimated Departure Time: </b>{formatTime(transaction.requestedDepartureTime)}</div>
          </>}
          <br />
          {finished ? <span style={{color:"#FB8500"}}><b>Charging session was stopped</b></span> :
            <button
              className={`button is-primary`}
              onClick={onSubmit}
              disabled={waiting}
              style = {{backgroundColor:'#FB8500'}}
            >
              {waiting ? "Stopping Charging..." : "Stop Charging"}
            </button>
          }
          <br />
          <br />
        </div>
    </div>
  );
}

export default StopTransaction