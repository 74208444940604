import { useRef, useState, useEffect } from "react";
import { BsEvStation } from "react-icons/bs";
import { BsEvStationFill } from "react-icons/bs";



const Index = ({data}) => {


    let [ colour, setColour ] = useState("");
    let [link, setLink] = useState("");

    

    useEffect(() => {
        if(data.status == "Occupied")  {
            setColour("#AA0000");
            setLink("");
        } else if(data.status == "Available") {
            setColour("#3E5393");
            setLink(`#/charger/`+data.id);
        } else if(data.status == "Charging") {
            setColour("#00AA00");
            setLink(`#/charger/`+data.id);
        } else {
            setColour("#ccc");
            setLink("");
        }
      }, [data]);
    

    const onClick = () => {
        if(link != "") window.location.href=link;
    };


  return (
    
    <tr onClick={onClick} style={{border:"0px solid", cursor:"pointer", lineHeight:"40px", color:`${colour}`}}>
        {data.private ? 
            <>
            <td><BsEvStationFill/></td>
            <td><b>{data.id}</b></td>
            <td><b>{data.status}</b></td>
            </>
            :<>
            <td><BsEvStation/></td>
            <td>{data.id}</td>
            <td>{data.status}</td>
            </>
        }
    </tr>
  );
};

export default Index;
