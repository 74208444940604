import { useRef, useState, useEffect } from "react";
import { ImPower } from "react-icons/im";
import { MdOutlineStar } from "react-icons/md";

const blupointstyle = {color:"#FB8500", fontSize:"25px", paddingTop:"10px"};

const Index = ({data, setTransaction}) => {

    let [ colour, setColour ] = useState("");
    let [ backgroundColour, setBackgroundColour ] = useState("");
    
    const onClick = () => {
        if(data.finishedAt) {
            setTransaction(data);
        } else {
            window.location.href="#/charger/"+data.chargerID;
        }
    }

    useEffect(() => {
        if(data.finishedAt)  {
            setColour("#3E5393");
        } else {
            setColour("#00AA00");
        }
    }, [data]);

  return (
    <>
    <tr onClick={onClick} style={{cursor:"pointer", lineHeight:"40px", color:`${colour}`}}>
        <td><ImPower/></td>
        <td>{data.startedAt.slice(2,16)}</td>
        { data.bluscore <= 33 ?
            <td className="bluscore_cell" style={{paddingLeft:"20px"}}><div className={`bluscore_box_small bluscore_a`}></div></td>
            : data.bluscore <= 66 ?
            <td className="bluscore_cell" style={{paddingLeft:"20px"}}><div className={`bluscore_box_small bluscore_b`}></div></td>
            :
            <td className="bluscore_cell" style={{paddingLeft:"20px"}}><div className={`bluscore_box_small bluscore_c`}></div></td>
        }
        <td style={{textAlign:"center"}}><MdOutlineStar style={blupointstyle}/>{data.blupoints}</td>
        <td style={{textAlign:"right"}}>{data.energyCharged?.toFixed(2)}</td>
    </tr>
    </>
  );
};

export default Index;
