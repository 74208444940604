
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { AiFillCar } from "react-icons/ai";
import { TbSteeringWheel, TbSteeringWheelOff } from "react-icons/tb";
import LogoPvg from "../../assets/bluwave-logo.png";

const iconStyle = {color:"hsl(229, 53%, 53%)", cursor:"pointer", verticalAlign:"middle", fontSize:'30px'}

const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={LogoPvg} />
);
const RenderEnodeIcon = (props) => (
  <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M14.26 6.408c.766 0 1.34.297 1.722.743a8.033 8.033 0 0 0-15.975.054c-.025.25.016.502.12.731a1.761 1.761 0 0 0 3.243-.052.922.922 0 0 0 .06-.286c0-.014 0-.027.004-.04a4.586 4.586 0 0 1 9.072-.364c.381-.467.965-.786 1.754-.786Z" fill="currentColor"></path><path d="M14.435 7.204c-.785-.081-1.538.401-1.825 1.11a.48.48 0 0 0-.029.13 4.584 4.584 0 0 1-9.041.495c-.375.54-.994.915-1.853.915C1 9.854.468 9.612.09 9.24a8.03 8.03 0 0 0 15.908-.348.48.48 0 0 0 0-.103c-.095-.801-.744-1.5-1.562-1.585Z" fill="currentColor"></path><path d="M8.145 9.792a1.865 1.865 0 1 0 0-3.73 1.865 1.865 0 0 0 0 3.73Z" fill="currentColor"></path></svg>
);

const validate = (values) => {
    const errors = {};
    if (!values.brand) {
      errors.brand = "Brand Required";
    }
  
    if (!values.year) {
      errors.year = "Year Required";
    } else if (!(Number.isInteger(Number(values.year)) && Number(values.year) > 0)) {
      errors.year = "Year must be a positive integer";render
    }
    if (!values.model) {
      errors.model = "Model Required";
    }
    if (!values.batteryCapacity) {
      errors.batteryCapacity = "Battery Capacity Required";
    } else if  (Number(values.batteryCapacity) < 0) {
      errors.batteryCapacity = "Battery capacity must be positive";
    }
  
    return errors;
  };
  
  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
  }) => (
    <div>
      <input {...input} placeholder={label} type={type} className="input" />
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-danger">{warning}</p>))}
    </div>
  );
  
  class CarForm extends Component {
    onSubmit = (formValues) => {
      this.props.onSubmit(formValues);
    };

  
    render() {
      const { errors, handleSubmit, pristine, submitting, loading, registerSmartcar, deregisterSmartcar, registerEnode, deregisterEnode } = this.props;

      return (
        <form className="box" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="field has-text-centered"><RenderLogo width="180" /></div>
          <div className="field has-text-centered"><AiFillCar style={iconStyle}/></div>

          <div className="field input--">
            <label className="label">Brand</label>
            <div className="control">
              <Field
                name="brand"
                component={renderField}
                label=""
              />
            </div>
          </div>
          <div className="field input--">
            <label className="label">Model</label>
            <div className="control">
              <Field
                name="model"
                component={renderField}
                label=""
              />
            </div>
          </div>
          <div className="field input--">
            <label className="label">Year</label>
            <div className="control">
              <Field
                type="number"
                name="year"
                component={renderField}
                label=""
                parse={value => parseInt(value, 10) || ""}
              />

            </div>
          </div>
          <div className="field input--">
            <label className="label">Battery Capacity (kWh)</label>
            <div className="control">
              <Field
                type="number"
                name="batteryCapacity"
                component={renderField}
                label=""
                parse={value => parseInt(value, 10) || ""}
              />

            </div>
          </div>

          <div className="field has-text-centered">
            <p className="help is-danger">{errors}</p>
            <br />
            <button
              className={`button is-primary ${loading ? `is-loading` : ``}`}
              disabled={pristine || submitting}
            >
              {this.props.initialValues ? "Update Your Car" : "Add Your Car"}
            </button>
            <br />
            <br />
          </div>

          <div style={{textAlign:"center", fontWeight:"bold"}}>
            <div style={{display:"inline-block"}}>
              {this.props.initialValues && (this.props.initialValues.isEnode ? 
              <RenderEnodeIcon onClick={deregisterEnode} style={{width:"34px", height:"34px", color:"hsl(93, 60%, 37%)", cursor:"pointer"}}/>
              : <RenderEnodeIcon onClick={registerEnode} style={{width:"34px", height:"34px", color:"hsl(93, 60%, 37%)", cursor:"pointer"}}/>)}
                <div>{this.props.initialValues && (this.props.initialValues.isEnode ? "Disconnect from Enode" : "Connect to Enode")}</div>
            </div>
          </div>
          <br/>
          <div style={{textAlign:"center", fontWeight:"bold"}}>
            <div style={{display:"inline-block"}}>
              {this.props.initialValues && (this.props.initialValues.isSmartcar ? 
              <TbSteeringWheelOff onClick={deregisterSmartcar} style={{fontSize:"34px", cursor:"pointer"}}/> 
              : <TbSteeringWheel onClick={registerSmartcar} style={{fontSize:"34px", cursor:"pointer"}}/>)}
                <div>{this.props.initialValues && (this.props.initialValues.isSmartcar ? "Disconnect from SmartCar" : "Connect to Smartcar")}</div>
            </div>
          </div>

        </form>
      );
    }
  }
  
  export default reduxForm({
    form: "car",
    validate,
  })(CarForm);