
import React, { Component } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import LogoPvg from "../../assets/bluwave-logo.png";
import { TbSteeringWheel, TbSteeringWheelOff } from "react-icons/tb";


const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const StartCharging = (props) => {
  let {SOC, pluggedIn, car, onSubmit, waiting, error} = props;

  const iconPowerStyle = {fontSize:'30px', verticalAlign:"middle", color: "#000000"}

  const fullyCharged = SOC >= 100;

  return (
    <div className="box">
        <div style={{justifyContent:"space-between", display:"flex", lineHeight:"56px", fontSize:"18px", color:"#00AA00"}}>
            <HomeIcon/>
            <RenderLogo width="160" />
            <Menu/>
        </div>
        <div className="field has-text-centered">
          <br/><div><TbSteeringWheel  style={iconPowerStyle}/></div><br />
          <div style={{margin:"8px"}}>Your</div>
          <div><b>{car ? (car.brand + " " + car.model + " " + car.year) : "Car not found"}</b></div>
          {!pluggedIn ?
            <>
              <div style={{margin:"8px"}}>is currently not plugged in! </div>
              <div><b>The SoC of your battery is {SOC}%</b></div>
            </>
            :
            <>
              {fullyCharged ? <div>is currently fully charged</div> :
                <>
                  <div>is currently not charging with a </div>
                  <div><b>SoC of {SOC}%</b></div>
                </>
              }
            </>
          }
          <br />

          {error && <div className="help is-danger" style={{marginBottom:"10px"}}>{error}</div>}
          {pluggedIn && !fullyCharged &&
            <button
              className={`button is-primary`}
              onClick={onSubmit}
              disabled={waiting}
              style = {{backgroundColor:'#FB8500'}}
            >
              {waiting ? "Resuming Charging..." : "Resume Charging"}
            </button>
          }
          <br />
          <br />
        </div>
    </div>
  );
}

export default StartCharging