
import LogoPvg from "../../assets/bluwave-logo.png";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import "../../energymix.css";

import { useQuery } from "react-query";
import {fetchUserData} from "../../utils/queries";
import { regionCodes } from "../../utils/config";

const RenderLogo = (props) => (
    <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
  );

const BluscoreForecast= (props) => {

    const { data, isLoading } = useQuery(
        "fetchUserData",
        fetchUserData,
        {onError: (error) => {
        }},
    );

    if(isLoading) return (
        <></>
    );
    return (
        <section className="hero is-primary is-fullheight">
            <div className="hero-body">
                <div className="container">
                <div className="columns is-centered">
                    <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                        <div className="box">
                            <div style={{justifyContent:"space-between", display:"flex"}}>
                                <HomeIcon/>
                                <RenderLogo width="160" />
                                <Menu/>
                            </div>
                            <div style={{marginTop:"30px", textAlign:"center"}}><b>{regionCodes[data?.region || "CA-ON"].Name} - BluScore</b></div>
                            <div id="wrap">
                            <iframe id="scaled-frame" style={{marginTop:"-100px", marginLeft:"-10px"}} src={"https://visual.dev.bluwave-ai.com/d/qVBxjklIk/bluscore-forecast?orgId=1&theme=light&var-zone="+data?.region || "CA-ON"}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
};




export default BluscoreForecast;