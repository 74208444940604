
import { useEffect } from "react";
import axios from "../../utils/raxios";
import { useState } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import {fetchChargerData, fetchUserData, fetchTransactionData} from "../../utils/queries";
import StartCharging from "./startcharging";
import StopCharging from "./stopcharging";
import LoadIcon from "../../assets/loading-icon-animated-gif-9.jpg";
import LogoPvg from "../../assets/bluwave-logo.png";
import { TbSteeringWheel } from "react-icons/tb";

import { useQuery, useQueryClient } from "react-query";

import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const RenderLoadIcon = (props) => (
  <img {...props} alt="Loadin" src={[LoadIcon]} />
);

const iconPowerStyle = {fontSize:'30px', verticalAlign:"middle", color: "#000000"}


const SmartcarCharging = (props) => {

  let { id } = useParams();
  let [ error, setError ] = useState("");
  let [sendingRequest, setSendingRequest] = useState(false);

  let [SOC, setSOC] = useState(0);
  let [pluggedIn, setPluggedIn] = useState(false);
  let [isCharging, setIsCharging] = useState(false);
  let [gotChargingData, setGotChargingData] = useState(false);
  let [car, setCar] = useState({});


  const { data, isLoading } = useQuery(
    "fetchUserData",
    fetchUserData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  
  const onStartCharging = () => {
    setSendingRequest(true);
    const data = {carID: parseInt(id)}
    axios.post("/startsmartcartransaction", data).then((response) => {
      setSendingRequest(false);
      setIsCharging(true);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server");
      setSendingRequest(false);
    });
  };

  const onStopCharging = () => {
    setSendingRequest(true);
    const data = {carID: parseInt(id)}
    axios.post("/stopsmartcartransaction", data).then((response) => {
      setSendingRequest(false);
      setIsCharging(false);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server");
      setSendingRequest(false);
    });
  };


  useEffect(() => {
    axios.get("/smartcarchargingstatus?car="+encodeURIComponent(id)).then((response) => {
      setSOC(response.data.soc);
      setIsCharging(response.data.charging);
      setPluggedIn(response.data.pluggedIn);
      setGotChargingData(true);
    }).catch((error) => {
          console.log(error);
          if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
          else if(error.response) setError("Invalid request")
          else setError("No response from server"); 
    });
  }, []);


  
  useEffect(() => {
    if(data && Array.isArray(data.cars)) {
      const cars = data.cars;
      let foundCar = cars.find(car => car.id == id);
      if (!foundCar) {
        setError("No car by that ID");
      } else {
        setCar(foundCar);
      }
    }
  }, [data, isLoading]);

  if(!gotChargingData || isLoading) {
    if(error) {
      return (
        <section className="hero is-primary is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                  <div className={"box has-text-centered"}>
                      <div style={{justifyContent:"space-between", display:"flex"}}>
                          <HomeIcon/>
                          <RenderLogo width="160" />
                          <Menu/>
                      </div>
                      <div className="help is-danger" style={{margin:"20px"}}>{error}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return (
      <section className="hero is-primary is-fullheight">
        <div className="hero-body">
          <div className="container">
              <div className="columns is-centered">
                <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                  <div className={"box has-text-centered"}>
                      <div style={{justifyContent:"space-between", display:"flex"}}>
                          <HomeIcon/>
                          <RenderLogo width="160" />
                          <Menu/>
                      </div>
                      <br/>
                      <TbSteeringWheel style={iconPowerStyle}/>
                      <br/>
                      <br/>
                      <div>Be patient ... </div>
                      <RenderLoadIcon width="100" />
                      <div>we are retrieving the charging status of your car ...</div>
                      <br/>
                      <br/>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
              {isCharging ? 
              <StopCharging SOC={SOC} car={car} onSubmit={onStopCharging} waiting={sendingRequest} error={error}/> 
              : <StartCharging  SOC={SOC} car={car} onSubmit={onStartCharging} waiting={sendingRequest} pluggedIn={pluggedIn} error={error}/>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default SmartcarCharging;
