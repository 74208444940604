
import axios from "../../utils/raxios";
import RegisterForm from "./registerform";
import { useState } from "react";
import LogoPvg from "../../assets/bluwave-logo.png";


const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const ForgotPassword = (props) => {

  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const changeEmail = (event) => {
    setEmail(event.target.value);
  }

  const onSubmit = () => {
    setSubmitting(true);
    axios.get("/forgotpassword?email="+email).then((response) => {
      setSuccess(true);
      setSubmitting(false);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
      else if(error.response) setErrors("Invalid request")
      else setErrors("No response from server");
      setSubmitting(false);
    });
  };

  const loginButton = () => {
    window.location.href = "#/login";
  };

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
              {success ? 
                <div className={"box has-text-centered"}>
                  <p>We sent you a password reset link to your Email address.</p>
                  <br />
                  <p>Please click on the link to reset your password.</p>
                  <br />
                  <button className="button is-primary" onClick={loginButton}>
                        Login
                  </button>
                </div>
                :
                <div className={"box has-text-centered"}>
                  <br />
                  <RenderLogo width="160" />
                  <br />
                  <input value={email} onChange={changeEmail} placeholder="email" type="text" className="input" />
                  <div className ="field has-text-centered">
                    <p>We will send a password reset link to this Email address.</p>
                    <br />
                    <p className="help is-danger">{errors}</p>
                    <br />
                    <button
                      className={`button is-primary`}
                      disabled={submitting}
                      onClick={onSubmit}
                    >
                      Send
                    </button>
                    <br />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default ForgotPassword;
