import TransactionButton from "./transaction"
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";

const columns = [
  {
    Header: "Start time",
    accessor:"startedAt",
    id: "time",
  },
  {
    Header: "BluScore",
  },
  {
    Header: "BluPoints",
  },
  {
    Header: "Energy (kWh)",
    accessor:"energyCharged",
    id: "energy",
  },
]

const Index = ({data, setCurrentTransaction, hide}) => {


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 20 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
  );

  if(hide) return "";

  return (
    <>
      <table className="table is-fullwidth" {...getTableProps()}>
        <thead>
          <>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th></th>
                {headerGroup.headers.map((column) => {
                  return (
                    <th 
                      className="is-size-7 has-text-centered"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
                <TransactionButton data={row.original} setTransaction={setCurrentTransaction} key={row.id}/>
            );
          })}
        </tbody>
      </table>

      <div className="">
        <button
          className="button is-light"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>{" "}
        <button
          className="button is-light"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
      </div>
    </>
  );

}






export default Index;

