
import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import LogoPvg from "../../assets/bluwave-logo.png";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import {BiShow} from "react-icons/bi";


const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = "First name Required";
    }
    if (!values.lastname) {
      errors.lastname = "Last name Required";
    }
  
    return errors;
  };
  
  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
  }) => (
    <div>
      <input {...input} placeholder={label} type={type} className="input" />
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-danger">{warning}</p>))}
    </div>
  );
  
  const ProfileForm = (props) => {
    const onSubmit = (formValues) => {
      props.onSubmit(formValues);
    };

    let [displayPassword, setDisplayPassword] = useState(false);

    const ToggleDisplayPassword = () => {
      setDisplayPassword(!displayPassword);
    }
  
    const { errors, handleSubmit, pristine, submitting, loading, success, waiting } = props;
    return (
      <form className="box" onSubmit={handleSubmit(onSubmit)}>
          <div style={{justifyContent:"space-between", display:"flex"}}>
              <HomeIcon/>
              <RenderLogo width="160" />
              <Menu/>
          </div>
        <div className="field input--add_extra_margin">
          <label className="label">First Name</label>
          <div className="control">
            <Field
              name="firstName"
              component={renderField}
              label=""
            />

          </div>
        </div>
        <div className="field input--add_extra_margin">
          <label className="label">Last Name</label>
          <div className="control">
            <Field
              name="lastName"
              component={renderField}
              label=""
            />

          </div>
        </div>
        <div className="field input--add_extra_margin">
          <label className="label">ID Tag</label>
          <div className="control">
            <Field
              name="idtag"
              component={renderField}
              label=""
            />

          </div>
        </div>

        <div className="field has-text-centered">
          <p className="help is-danger">{errors}</p>
          {success && <p className="help">Successfully updated profile.</p>}
          <br />
          <button
            className={`button is-primary ${loading ? `is-loading` : ``}`}
            disabled={pristine || submitting || waiting}
          >
            {waiting? "Updating..." : "Update"}
          </button>
          <br />
          <br />
        </div>
      </form>
    );
  }
  
  export default reduxForm({
    form: "register",
    validate,
  })(ProfileForm);