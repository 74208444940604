import axios from "./raxios";

//Add a response interceptor

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error);

    if (error.response && error.response.status === 401) {
      window.location.href = "#/login"
    }
    return Promise.reject(error);
  }
);
