import { useRef, useState, useEffect } from "react";
import { Menu, Button } from "@mantine/core";
import axios from "../utils/raxios";
import { AiOutlineMenu } from "react-icons/ai";
import { useAuth0 } from "@auth0/auth0-react";




const Index = () => {

  const { logout } = useAuth0();
  const Logout = () => {
    axios.post("/logout").then((response) => {
        logout({logoutParams: {returnTo: window.location.origin+window.location.pathname+"#/login"}});
    }).catch((error) => {
        
   });
  };

  const ChargerPage = () => {
    window.location.href="#/chargers";
};
const ProfilePage = () => {
    window.location.href="#/profile";
};

const TransactionsPage = () => {
    window.location.href="#/transactions";
  }





  return (
    <Menu style={{color:"hsl(229, 53%, 53%)", backgroundColor:"transparent", padding:"0"}}>
        <Menu.Target>
            <Button><AiOutlineMenu style={{width:"32px", height:"32px", padding:"0"}}/></Button>
        </Menu.Target>
        <Menu.Dropdown>
            <Menu.Item
                className="has-text-dark is-size-7 is-medium"
                style={{ cursor: "pointer" }}
                onClick={ProfilePage}
            >
                My Profile
            </Menu.Item>
            <Menu.Item
                className="has-text-dark is-size-7 is-medium"
                style={{ cursor: "pointer" }}
                onClick={ChargerPage}
            >
                Chargers
            </Menu.Item>
            <Menu.Item
                className="has-text-dark is-size-7 is-medium"
                style={{ cursor: "pointer" }}
                onClick={TransactionsPage}
            >
                My Charging Sessions
            </Menu.Item>
            <Menu.Item
                className={`has-text-dark is-size-7 is-medium`}
                style={{ cursor: "pointer" }}
                onClick={Logout}
            >
                Logout
            </Menu.Item>
        </Menu.Dropdown>
    </Menu>
  );
};

export default Index;