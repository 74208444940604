
import { useEffect } from "react";
import axios from "../../utils/raxios";
import { useState } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import {fetchChargerData, fetchUserData, fetchTransactionData, fetchBluscore} from "../../utils/queries";
import StartTransaction from "./starttransaction";
import StopTransaction from "./stoptransaction";

import { useQuery, useQueryClient } from "react-query";

import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


export const maxChargingRate = 5;

const formatTime = (time) => {
  if(!time) return ""
  let currentTime = new Date();
  let hours = time.hour();
  let minutes = time.minute();

  let dateOffset = 0;
  if(currentTime.getHours() > hours || (currentTime.getMinutes() >= minutes && currentTime.getHours() == hours)) {
    dateOffset = 1;
  }
  let newTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate() + dateOffset, hours, minutes, 0, 0);

  return newTime.getFullYear()+"-"+padTime(newTime.getMonth()+1)+"-"+padTime(newTime.getDate())+" "+padTime(newTime.getHours())+":"+padTime(newTime.getMinutes())+":00";
}

const padTime = (number) => {
  return ("0"+number).slice(-2);
}

const Chargers = (props) => {

  let { id } = useParams();
  let [ error, setError ] = useState("");
  let [ formErrors, setFormErrors ] = useState("");
  let [sendingRequest, setSendingRequest] = useState(false);
  let [finishedStopTransaction, setFinishedStopTransaction] = useState(false);

  const queryClient = useQueryClient();


  
  const onStartTransaction = (formValues) => {


    let editedFormValues = {...formValues}
    if(editedFormValues.optOut) {
      delete editedFormValues.preferredDepartureTime;
      delete editedFormValues.preferredDepartureSOC;
      delete editedFormValues.arrivalSOC;
    } else {
      let formattedTime = formatTime(formValues.preferredDepartureTime);
      editedFormValues.preferredDepartureTime = formattedTime;
    }
    editedFormValues.carID = editedFormValues.car.ID;
    delete editedFormValues.car;
    editedFormValues.chargePointID = id;

    let currentDate = new Date();
    let departureDate = new Date(editedFormValues.preferredDepartureTime);

    let timeDifferenceHours = (departureDate.getTime() - currentDate.getTime()) / 3600000;
    let energyRequired = (Number(formValues.preferredDepartureSOC) - Number(formValues.arrivalSOC))/100 * formValues.car.capacity

    if(!editedFormValues.optOut && (energyRequired / timeDifferenceHours) > maxChargingRate) {
      let newDepartureDate = dayjs().add(energyRequired / maxChargingRate + 0.05, 'hour')
      let newDepartureString = padTime(newDepartureDate.hour())+":"+padTime(newDepartureDate.minute());

      confirmAlert({
        message:`It is impossible to charge `+energyRequired+` kW until your selected Departure Time.\n\nDo you want to change the Estimated Departure Time to `+newDepartureString+`?`,
        buttons: [
          {
            label:"Change and Start Charging",
            onClick: () => {
              formValues.preferredDepartureTime = newDepartureDate;
              editedFormValues.preferredDepartureTime = formatTime(newDepartureDate);
              SendStartTransactionRequest(editedFormValues)
            }
          },
          {
            label:"Cancel and Adjust Yourself",
          }
        ]
      });
    } else {
      SendStartTransactionRequest(editedFormValues);
    }
  };

  const SendStartTransactionRequest = (editedFormValues) => {
    console.log(editedFormValues);
    setSendingRequest(true);
    axios.post("/start_transaction", editedFormValues).then((response) => {
      queryClient.invalidateQueries("fetchChargerData");
      queryClient.invalidateQueries("fetchTransactionData");
      queryClient.invalidateQueries("fetchUserData");
      setSendingRequest(false);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setFormErrors(error.response.data.description);
      else if(error.response) setFormErrors("Invalid request")
      else setFormErrors("No response from server");
      setSendingRequest(false);
    });
  }

  const onStopTransaction = () => {
    setSendingRequest(true);
    axios.post("/stop_transaction", {chargePointID: id}).then((response) => {
      queryClient.invalidateQueries("fetchChargerData");
      queryClient.invalidateQueries("fetchTransactionData");
      setFinishedStopTransaction(true);
      setSendingRequest(false);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server");
      setSendingRequest(false);
    });
  };

  
  const { data = [], isLoading } = useQuery(
    "fetchChargerData",
    fetchChargerData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );


  const { data:userdata, isLoading:isLoadingUser } = useQuery(
    "fetchUserData",
    fetchUserData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  const { data:transactiondata, isLoading:isLoadingTransaction } = useQuery(
    "fetchTransactionData",
    fetchTransactionData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );


  if(isLoading || isLoadingUser || isLoadingTransaction) return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen"></div>
          </div>
        </div>
      </div>
    </section>
  );

  if(error) {
    return (
      <section className="hero is-primary is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                <div className={"box has-text-centered"}>
                    <div style={{justifyContent:"space-between", display:"flex"}}>
                        <HomeIcon/>
                        <Menu/>
                    </div>
                    <div>{error}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }




  let initialCar = null;
  let cars = [];
  let charger = {}; 

  if(userdata && Array.isArray(userdata.cars)) {
    cars = userdata.cars;
    let car = cars.find(car => !transactiondata.find(transaction => !transaction.finishedAt && car.id === transaction.carID));
    if(car) initialCar = {ID: car.id, capacity: car.batteryCapacity};
  }

  
  charger = data.find(element => element.id === id);
  if(charger === undefined || (charger.status !== "Available" && charger.status !== "Charging")) {
    window.location.href="#/chargers";
  }

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
              {!finishedStopTransaction && charger.status == "Available" ? 
              <StartTransaction initialValues={{car: initialCar, preferredDepartureTime: dayjs().add(6, 'hour')}} charger={charger} user={userdata} cars={cars} onSubmit={onStartTransaction} waiting={sendingRequest} errors={formErrors} transactions={transactiondata} /> 
              : <StopTransaction cars={cars} charger={charger} onSubmit={onStopTransaction} waiting={sendingRequest} transactions={transactiondata} finished={finishedStopTransaction}/>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default Chargers;
