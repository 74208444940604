
import { useEffect, useState } from "react";
import axios from "../../utils/raxios";
import { useParams } from "react-router-dom";
import ResetPasswordForm from "./resetpasswordform";


const ResetPassword = (props) => {

  let { email, token } = useParams();

  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);

  const onSubmit = (formValues) => {
    let editedFormValues = {...formValues}
    editedFormValues.email = email;
    editedFormValues.token = token;
    axios.post("/resetpassword", editedFormValues).then((response) => {
      loginButton();
      //setSuccess(true);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
      else if(error.response) setErrors("Invalid request")
      else setErrors("No response from server");
    });
  };

  const loginButton = () => {
    window.location.href = "#/login";
  };

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
              {success ? 
                <div className={"box has-text-centered"}>
                  <p>Successfully Changed Password</p>
                  <button className="button is-primary" onClick={loginButton}>
                        Login
                  </button>
                </div>
                :
                <ResetPasswordForm
                  onSubmit={onSubmit}
                  errors={errors}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default ResetPassword;
