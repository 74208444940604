import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";

class ErrorHandler extends Component {
  render() {
    return (
      <>
        <section className="section">
          <div className="columns ">
            <div className="column has-text-centered">
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <h1 className="title"> 404</h1>
              <h1 className="subtitle"> Page not found</h1>
              <p>Sorry, We couldn't find the page you were looking for.</p>{" "}
              <p>We suggest that you return to the main page.</p>
              <br />
              <Link className="button" to="/">
                Go to main page
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const RedirectAs404 = ({ location }) => (
  <Navigate to={Object.assign({}, location, { state: { is404: true } })} />
);

export { RedirectAs404 };

export default ErrorHandler;
