
import { useEffect } from "react";
import axios from "../../utils/raxios";
import { useParams } from "react-router-dom";
import { useState } from "react";


const VerifyEmail = (props) => {

  let { email, token } = useParams();
  let [ result, setResult ] = useState("");

  useEffect(() => {
    axios.get("/verifyemail?email="+encodeURIComponent(email)+"&token="+encodeURIComponent(token)).then((response) => {
        setResult("Your account is now active!")
    }).catch((error) => {
        if(error.response && error.response.data && error.response.data.description) setResult(error.response.data.description);
        else if(error.response) setResult("Invalid request")
        else setResult("No response from server"); 
   });
  }, []);

  const onSubmit = () => {
    window.location.href = "#/login";
  };

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                {result &&
                    <div className={"box has-text-centered"}>
                        <div>{result}</div>
                        <br />
                        <button className="button is-primary" onClick={onSubmit}>
                        Go To Login Page
                        </button>
                    </div>
                }

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default VerifyEmail;
