
import { useEffect } from "react";
import axios from "../../utils/raxios";
import { useState } from "react";
import UpdateCar from "./updatecar";
import Bluscore from "../../components/bluscore/bluscore";
import Menu from "../../components/menu";
import {fetchUserData, fetchChargerData, fetchTransactionData} from "../../utils/queries";
import ChargerTable from "../../components/chargertable/chargerTable";
import TransactionTable from "../../components/transactionTable/dashboardtable";
import SelectedTransaction from "../../components/transactionTable/selectedTransaction";
import { MdOutlineStar } from "react-icons/md";
import LogoPvg from "../../assets/bluwave-logo.png";

import { useQuery, useQueryClient } from "react-query";
import { width } from "@mui/system";

const RenderLogo = (props) => (
  <img {...props} alt="Bluwave Logo" src={[LogoPvg]} />
);

const blupointstyle = {color:"#FB8500", fontSize:"35px", paddingTop:"5px", margingLeft:"20px"};


const Dashboard = (props) => {

  let [transaction, setCurrentTransaction ] = useState(false);
  let [ error, setError ] = useState("");

  const queryClient = useQueryClient();
  
  const { data, isLoading } = useQuery(
    "fetchUserData",
    fetchUserData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  
  const { data:chargerData, isLoading:isLoadingCharger } = useQuery(
    "fetchChargerData",
    fetchChargerData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  
  const { data:transactiondata, isLoading:isLoadingTransaction } = useQuery(
    "fetchTransactionData",
    fetchTransactionData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );




  const backButton = () => {
    setCurrentTransaction(false);
  }
  

  if(isLoading || isLoadingCharger || isLoadingTransaction) return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
              <Bluscore hide={true} key="bluscore" defaultRegion={data?.region} alreadySetRegion={data?.setRegion}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const refetchDashboard = () => {
    queryClient.invalidateQueries("fetchUserData");
  }

  const ChargerPage = () => {
    window.location.href="#/chargers";
  }
  const TransactionsPage = () => {
    window.location.href="#/transactions";
  }

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                {error ?
                    <div className={"box has-text-centered"}>
                        <div>{error}</div>
                    </div>
                    :
                    <div className={"box has-text-centered"}>
                      {transaction ? 
                        <>
                        <div style={{justifyContent:"space-between", display:"flex", lineHeight:"56px", fontSize:"18px"}}>
                        <table width={"100%"}><tbody><tr>
                            <td style={{textAlign:"left", width:"50%"}}><RenderLogo width="160" /></td>
                            <td style={{textAlign:"right", width:"50%"}}><Menu/></td>
                          </tr></tbody></table>
                        </div>
                        <div>{transaction && <span><b>Charging Session Summary</b></span>}</div>
                        <SelectedTransaction transaction={transaction} cars={data.cars} backButton={backButton}/>
                        </>
                      : 
                      <>
                        <div>
                          <table width={"100%"}><tbody><tr>
                            <td style={{textAlign:"left", verticalAlign:"middle", width:"33%"}}><RenderLogo width="160" /></td>
                            <td style={{textAlign:"right"}}><MdOutlineStar style={blupointstyle}/></td>
                            <td style={{width:"30%", verticalAlign:"middle", color:"#425498"}}>{data.blupoints} BluPoints</td>
                            <td style={{textAlign:"right", verticalAlign:"middle", width:"23%"}}><Menu/></td>
                          </tr></tbody></table>
                        </div>
                        <table style={{width:"100%", marginTop:"10px"}} border="0">
                          <tbody>
                          <tr>
                            <td colSpan={3}>
                              <Bluscore key="bluscore" defaultRegion={data?.region} alreadySetRegion={data?.setRegion}/>
                            </td>
                          </tr>
                            <tr style={{lineHeight:"40px"}}>
                              <td colSpan={3}>                          
                                <UpdateCar
                                  car={null}
                                  refetch={refetchDashboard}
                                />
                              </td>
                            </tr>
                            {data.cars?.map((row, index) => (
                                <UpdateCar
                                  key={row.id}
                                  car={row}
                                  refetch={refetchDashboard}
                                  transactions={transactiondata}
                                />
                            ))}
                          </tbody>
                        </table>
                        <div style={{border:"0px solid", lineHeight:"40px", marginTop:"8px", cursor:"pointer"}} onClick={ChargerPage}><b>Chargers ...</b></div>
                        <ChargerTable data={chargerData} numResults={3}/>
                        <div style={{border:"0px solid", lineHeight:"40px", marginTop:"8px", cursor:"pointer"}} onClick={TransactionsPage}><b>My Charging Sessions ...</b></div>
                        <TransactionTable data={transactiondata} numResults={3} setCurrentTransaction={setCurrentTransaction}/>
                      </>
                      }
                    </div>
                    
                }

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default Dashboard;
