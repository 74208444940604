
import { BiTrendingUp } from "react-icons/bi";
import { BiTrendingDown } from "react-icons/bi";
import { MdTrendingFlat } from "react-icons/md";

import axios from "../../utils/raxios";



import { useEffect } from "react";
import { useState } from "react";
import {fetchBluscore, fetchLocationData} from "../../utils/queries";
import { regionCodes } from "../../utils/config";

import { useQuery, useQueryClient } from "react-query";
import Select from "react-select";


import { confirmAlert } from 'react-confirm-alert';
import { padding } from "@mui/system";


const iconStyle = {color:"hsl(229, 53%, 53%)", cursor:"pointer", verticalAlign:"middle", fontSize:'30px'}




const getSelectRegionOptions = () => {

    let result = [];
    for(const code in regionCodes) {
        result.push({value: code, label: regionCodes[code].Name});
    }

    result.sort((a, b)=> {
        return a.label.localeCompare(b.label);
    });

    return result;
}

  
const Bluscore = ({hide, defaultRegion, alreadySetRegion}) => {

    let [region, setRegion] = useState(false);
    let [error, setError] = useState(false);
    let [score, setScore] = useState(false);
    let [trend, setTrend] = useState(false);

    const queryClient = useQueryClient();

    const { data:autoDetectRegion, isLoading:isLoadingLocation } = useQuery(
        "fetchLocationData",
        fetchLocationData,
        {onError: (error) => {
        if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
        else if(error.response) setError("Invalid request")
        else setError("No response from server"); 
        }},
    );

    const updateRegion = (newRegion, updateDatabase) => {
        const label = regionCodes[newRegion].Name
        if(label) {
            setRegion({value:newRegion, label: label});
            if(updateDatabase) {
                axios.post("/region", {code: newRegion}).then((response) => {
                    queryClient.invalidateQueries("fetchUserData");
                }).catch((error) => {

                });
            }

        }
    }

    const CheckAutoDetectRegion = (currentRegion) => {
        if(!alreadySetRegion && !isLoadingLocation && currentRegion && regionCodes[currentRegion] && regionCodes[autoDetectRegion] && currentRegion != autoDetectRegion) {
            confirmAlert({
                message:`You seem to be located in the utility zone (${regionCodes[autoDetectRegion].Name}), which does not match the selected zone (${regionCodes[currentRegion].Name}).`,
                buttons: [
                {
                    label:"Update Zone",
                    onClick: () => updateRegion(autoDetectRegion, true) 
                },
                {
                    label:"Cancel",
                    onClick: () => updateRegion(currentRegion, true) 
                }
                ]
            });
        }

    }
    

    useEffect(() => {
        CheckAutoDetectRegion(region?.value);
    }, [autoDetectRegion, isLoadingLocation]);

    useEffect(() => {

        if(!region && defaultRegion !== undefined) {
            if(regionCodes[defaultRegion]) {
                updateRegion(defaultRegion, false);
                CheckAutoDetectRegion(defaultRegion);
            } else {
                updateRegion("CA-ON", false);  
                CheckAutoDetectRegion("CA-ON");
            }
        }


    }, [defaultRegion]);





    const fetchBluscoreByRegion = async () => {
        return await fetchBluscore(region.value);
    }


    const { data:bluscoredata, isLoading:isLoadingBluscore } = useQuery(
        {
        queryKey: ["fetchBluscore", region],
        queryFn: fetchBluscoreByRegion,
        enabled: !isLoadingLocation && !!region,
        },
        {onError: (error) => {
        if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
        else if(error.response) setError("Invalid request")
        else setError("No response from server"); 
        }},
    );

    
      
    useEffect(() => {
        if(!isLoadingBluscore) {
            setTrend(bluscoredata?.trend || 0);
            setScore(bluscoredata?.bluscore || 0);
            
        }
    }, [bluscoredata, isLoadingBluscore]);

    if(score === false || hide || !region) {
        return "";
    }

    let trendDisplay = <MdTrendingFlat style={iconStyle}/>
    if(trend > 0) trendDisplay = <BiTrendingUp style={iconStyle}/>;
    else if (trend < 0) trendDisplay = <BiTrendingDown style={iconStyle}/>;

    const OnClick = () => {
        window.location.href="#/energymix";
    };

    const OnClickTrend = () => {
        window.location.href="#/bluscoreforecast";
    }
    
    const SelectRegion = ({value}) => (
        <Select styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              fontSize: "12px",
              padding: "0px",
              margin: "0px",
              height: "35px",
            }),
            option: (styles) => ({
                ...styles,
                fontSize: "12px",
            }),
          }}
            onChange={value => updateRegion(value.value, true)}
            options={getSelectRegionOptions()}
            defaultValue={value}
        />
    );
    
    return (
        <>

            {score <= 33 ?
                <table style={{width:"100%", fontWeight:"bold", color:"#425498", marginTop:"5px"}} border="0">
                <tbody>
                    <tr>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"37%", textAlign:"center", fontSize:"12px"}}>Current BluScore in <SelectRegion value={region}/></td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"20%", textAlign:"center"}}><div onClick={OnClick} className={`bluscore_box bluscore_a`}>{score}</div></td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"10%", textAlign:"center"}} onClick={OnClickTrend}>{trendDisplay}</td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"25%", textAlign:"center", fontSize:"13px", color:"#d73027"}} onClick={OnClickTrend}>Not the best time to charge!</td>
                    </tr>
                </tbody>
                </table>
                : score <= 66 ?
                <table style={{width:"100%", fontWeight:"bold", color:"#425498", marginTop:"5px"}} border="0">
                <tbody>
                <tr>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"37%", textAlign:"center", fontSize:"12px"}}>Current BluScore in <SelectRegion value={region}/></td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"20%", textAlign:"center"}}><div onClick={OnClick} className="bluscore_box bluscore_b">{score}</div></td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"10%", textAlign:"center"}} onClick={OnClickTrend}>{trendDisplay}</td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"25%", textAlign:"center", fontSize:"13px", color:"#FB8500"}} onClick={OnClickTrend}>Decent time to charge!</td>
                </tr>
                </tbody>
                </table>
                :
                <table style={{width:"100%", fontWeight:"bold", color:"#425498", marginTop:"5px"}} border="0">
                <tbody>
                <tr>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"37%", textAlign:"center", fontSize:"12px"}}>Current BluScore in <SelectRegion value={region}/></td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"20%", textAlign:"center"}}><div onClick={OnClick} className="bluscore_box bluscore_c">{score}</div></td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"10%", textAlign:"center"}} onClick={OnClickTrend}>{trendDisplay}</td>
                    <td className="bluscore_cell" style={{verticalAlign:"middle", width:"25%", textAlign:"center", fontSize:"12px", color:"#4575b4"}} onClick={OnClickTrend}>Great time to charge!</td>
                </tr>
                </tbody>
                </table>

            }
        
        </>
    );

}




export default Bluscore