
import { useEffect } from "react";
import { useState } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import LogoPvg from "../../assets/bluwave-logo.png";
import axios from "../../utils/raxios";



const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const Index = (props) => {

  let [ error, setError ] = useState("");


  useEffect(() => {

    const data = {}
    axios.post(`/updateenode`, data).then((response) => {
      window.location.href = window.location.origin+window.location.pathname+"#/dashboard";
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    });
  }, []);




  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                {error &&
                  <div className={"box has-text-centered"}>
                      <div style={{justifyContent:"space-between", display:"flex"}}>
                          <HomeIcon/>
                          <RenderLogo width="160" />
                          <Menu/>
                      </div>
                      <div>{error}</div>
                  </div>
                }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default Index;
