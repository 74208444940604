
import React, { Component } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import { useState, useEffect } from "react";
import { BsEvStation } from "react-icons/bs";
import { ImPower } from "react-icons/im";
import LogoPvg from "../../assets/bluwave-logo.png";
import { TbSteeringWheel, TbSteeringWheelOff } from "react-icons/tb";


const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);


const StopCharging = (props) => {
  let {SOC, car, onSubmit, waiting, error} = props;

  const iconPowerStyle = {fontSize:'30px', verticalAlign:"middle", color: "#000000"}
  return (
    <div className="box">
        <div style={{justifyContent:"space-between", display:"flex", lineHeight:"56px", fontSize:"18px", color:"#00AA00"}}>
            <HomeIcon/>
            <RenderLogo width="160" />
            <Menu/>
        </div>
        <div className="field has-text-centered">
           <br /><div><TbSteeringWheel style={iconPowerStyle}/></div><br />
          <div>Your</div>
          <div><b>{car ? (car.brand + " " + car.model + " " + car.year) : "Car not found"}</b></div>
          <div>is charging with a </div>
          <div><b>SoC of {SOC} %</b></div>
          <br />
          {error && <div>{error}</div>}
          <button
            className={`button is-primary`}
            onClick={onSubmit}
            disabled={waiting}
            style = {{backgroundColor:'#FB8500'}}
          >
            {waiting ? "Pausing Charging..." : "Pause Charging"}
          </button>
          <br />
          <br />
        </div>
    </div>
  );
}

export default StopCharging