import TransactionButton from "./transactionButton"

const Index = ({data, numResults, setCurrentTransaction}) => {
  
    return (
      <>
        <table style={{width:"100%"}} border="0">
          <tbody>
            {data?.map((row, index) => (
              (numResults == null || numResults > index) && <TransactionButton data={row} key={index} setTransaction={setCurrentTransaction}/>
            ))}
          </tbody>
        </table>
      </>
    );
}

export default Index;

