
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import Select from "react-select";
//import TimePicker from "react-time-picker";
import { TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { BsEvStation } from "react-icons/bs";
import { BsEvStationFill } from "react-icons/bs";
import LogoPvg from "../../assets/bluwave-logo.png";
import Bluscore from "../../components/bluscore/bluscore";
import { maxChargingRate } from "./toggletransaction";
import dayjs from "dayjs";


const iconStyle = {fontSize:'30px', verticalAlign:"middle", marginLeft:"20px"}

const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const validate = (values) => {
  
  const errors = {};
  if (!values.car?.ID) {
    errors.car = "Car Required";
  }


  if(!values.optOut) {
    if (values.arrivalSOC === undefined) {
      errors.arrivalSOC = "Arrival SoC Required";
    } else if (!(Number.isInteger(Number(values.arrivalSOC)) && Number(values.arrivalSOC) >= 0)) {
      errors.arrivalSOC = "Must be a positive integer";
    }

    if (!values.preferredDepartureTime) {
      errors.preferredDepartureTime = "Departure time required";
    }

    if (values.preferredDepartureSOC === undefined) {
      errors.preferredDepartureSOC = "Departure SoC Required";
    } else if  (!(Number.isInteger(Number(values.preferredDepartureSOC)) && Number(values.preferredDepartureSOC) > 0)) {
      errors.preferredDepartureSOC = "Must be a positive integer";
    } else if (Number(values.preferredDepartureSOC) < Number(values.arrivalSOC)) {
      errors.preferredDepartureSOC = "Preferred Departure SoC must be greater than Arrival SoC";
    }
  }
  return errors;
};

const parsePercentage = (text) => {
  if(text == "") return "";
  let num = Number(text)
  if(isNaN(num)) return "";
  return Math.min(Math.max(Math.floor(num), 0), 100);
}


const renderField = ({
  input,
  label,
  type,
  disabled,
  meta: { touched, error, warning },
}) => (
  <div>
    <input {...input} placeholder={label} type={type} className="input" disabled={disabled} />
    {touched &&
      ((error && <p className="help is-danger">{error}</p>) ||
        (warning && <p className="help is-danger">{warning}</p>))}
  </div>
);

const renderSelect = ({
  input,
  options,
  meta: { touched, error, warning },
}) => (
  <div>
    <Select
      onChange={value => input.onChange(value.value)}
      options={options}
      defaultValue={options[0] || "Select..."}
    />
    {touched &&
      ((error && <p className="help is-danger">{error}</p>) ||
        (warning && <p className="help is-danger">{warning}</p>))}
  </div>
);

const renderTimePicker = ({
  input,
  disabled,
  meta: { touched, error, warning },
}) => {return(
  <div>
    <TimePicker
      onChange={value => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      disableClock={true}
      value={input.value}
      disabled={disabled}
    />
    {touched &&
      ((error && <p className="help is-danger">{error}</p>) ||
        (warning && <p className="help is-danger">{warning}</p>))}
  </div>
);}

const StartTransactionForm = (props) => {
  let { errors, handleSubmit, pristine, submitting, loading, cars, charger, onSubmit, waiting, transactions, user, arrivalSOC, preferredDepartureSOC, car} = props;

  let [optOut, setOptOut] = useState(false);
  let [autoSelectTime, setAutoSelectTime] = useState(true);
  const onToggleOptOut = () => {
    props.dispatch(change("startTransaction", "optOut", !optOut));
    setOptOut(!optOut);
  }

  const AutoUpdateTimeArrivalSOC = (_, newValue) => {
    AutoUpdateTime(newValue, preferredDepartureSOC);
  }
  const AutoUpdateTimeDepartureSOC = (_, newValue) => {
    AutoUpdateTime(arrivalSOC, newValue);
  }

  const AutoUpdateTime = (currentSOC, departureSOC) => {
    if(autoSelectTime) {
      if(Number.isInteger(currentSOC) && Number.isInteger(departureSOC) && departureSOC > currentSOC && car?.ID) {
        let energyRequired = (departureSOC - currentSOC)/100 * car.capacity;
        let hours = energyRequired / maxChargingRate * 2;
        props.dispatch(change("startTransaction", "preferredDepartureTime", dayjs().add(hours, 'hour')));
      }
    }
  }

  return (
    <form className="box" onSubmit={handleSubmit(onSubmit)}>
        <div style={{justifyContent:"space-between", display:"flex"}}>
            <HomeIcon/>
            <RenderLogo width="160" />
            <Menu/>
        </div>
        <div style={{marginTop:"10px", marginBottom:"10px"}}>
          <Bluscore defaultRegion={user?.region} alreadySetRegion={user?.setRegion}/>
        </div>
        <div className="field has-text-centered">
          <span style={{lineHeight:"36px"}}>
          {charger.private?
           <>
            <div><BsEvStationFill  style={iconStyle}/> <b>{charger.id}</b></div>
          </>
           :
           <>
            <div><BsEvStation  style={iconStyle}/> <b>{charger.id}</b></div>
          </>
          }
          </span>            
        </div>
      <div className="field input--add_extra_margin">
        <label className="label">Car</label>
        <div className="control">
          <Field
            name="car"
            component={renderSelect}
            options={cars?.map((row, index) => (
              transactions.find(transaction => !transaction.finishedAt && transaction.carID == row.id) ? null
              : {value:{ID: row.id, capacity: row.batteryCapacity}, label: row.brand+" "+row.model}
            )).filter(Boolean)}
          />

        </div>
      </div>
      <div className="field input--add_extra_margin">
        <label className="label">Arrival SoC</label>
        <div className="control">
          <Field
            type="number"
            name="arrivalSOC"
            component={renderField}
            label=""
            parse={value => parsePercentage(value)}
            disabled={optOut}
            onChange={AutoUpdateTimeArrivalSOC}
          />

        </div>
      </div>
      <div className="field input--add_extra_margin">
        <label className="label">Preferred Departure SoC</label>
        <div className="control">
          <Field
            type="number"
            name="preferredDepartureSOC"
            component={renderField}
            label=""
            parse={value => parsePercentage(value)}
            disabled={optOut}
            onChange={AutoUpdateTimeDepartureSOC}
          />

        </div>
      </div>
      <div className="field input--add_extra_margin">
        <label className="label">Estimated Departure Time</label>
        <div className="control">
          <Field
            name="preferredDepartureTime"
            component={renderTimePicker}
            label=""
            disabled={optOut}
            //onChange//() => setAutoSelectTime(false)}
          />
        </div>
      </div>
      <div className="field input--add_extra_margin">
        <label className="label" style={{display:"inline-block", marginRight:"8px"}}>Opt-out of load optimization</label>
          <input
            type="checkbox"
            onChange={onToggleOptOut}
            value={optOut}
            style={{verticalAlign:"middle"}}
          />
      </div>

      <div className="field has-text-centered">
        <p className="help is-danger">{errors}</p>
        <br />
        <button
          className={`button is-primary ${loading ? `is-loading` : ``}`}
          disabled={pristine || submitting || waiting}
          style = {{backgroundColor:'#FB8500'}}
        >
          {submitting || waiting ? "Starting Charging..." : "Start Charging"}
        </button>
        <br />
        <br />
      </div>
    </form>
  );
}

const form = reduxForm({
  form: "startTransaction",
  validate,
})(StartTransactionForm);

const selector = formValueSelector('startTransaction');

export default connect(state => {
  const arrivalSOC = selector(state, 'arrivalSOC')
  const preferredDepartureSOC = selector(state, 'preferredDepartureSOC')
  const car = selector(state, 'car')
  return {
    arrivalSOC,
    preferredDepartureSOC,
    car,
  }
})(form)