
import axios from "../../utils/raxios";
import RegisterForm from "./registerform";
import { useState } from "react";


const Register = (props) => {

  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);

  const onSubmit = (formValues) => {
    axios.post("/register", formValues).then((response) => {
      setSuccess(true);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
      else if(error.response) setErrors("Invalid request")
      else setErrors("No response from server");
    });
  };

  const loginButton = () => {
    window.location.href = "#/login";
  };

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
              {success ? 
                <div className={"box has-text-centered"}>
                  <p>Thank you for signing up!</p>
                  <br />
                  <p>We sent you a verification link to your Email address.</p>
                  <br />
                  <p>Please click on the verification link to activate your account.</p>
                  <br />
                  <button className="button is-primary" onClick={loginButton}>
                        Login
                  </button>
                </div>
                :
                <RegisterForm
                  onSubmit={onSubmit}
                  errors={errors}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default Register;
