
import axios from "../../utils/raxios";
import ProfileForm from "./profileForm";
import { useState, useEffect } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import {fetchUserData} from "../../utils/queries";

import { useQuery, useQueryClient } from "react-query";






const Profile = (props) => {

  const [formErrors, setFormErrors] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [initialData, setInitialData] = useState({});

  
  const queryClient = useQueryClient();
  
  const { data, isLoading } = useQuery(
    "fetchUserData",
    fetchUserData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  useEffect(() => {
    if(data) {
        setInitialData({email:data.email, firstName: data.firstName, lastName: data.lastName, idtag: data.idtag, password: "", password2: ""})
    }
  }, [data]);


  const onSubmit = (formValues) => {
    setSending(true);
    setSuccess(false);
    axios.post("/profile", formValues).then((response) => {
      setSending(false);
      setSuccess(true);
      setFormErrors("");
      queryClient.invalidateQueries("fetchUserData")
    }).catch((error) => {
      setSending(false);
      if(error.response && error.response.data && error.response.data.description) setFormErrors(error.response.data.description);
      else if(error.response) setFormErrors("Invalid request")
      else setFormErrors("No response from server"); 
    });
  };



  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                {!isLoading &&
                error ? 
                    <div className={"box has-text-centered"}>
                        <div style={{justifyContent:"space-between", display:"flex"}}>
                            <HomeIcon/>
                            <RenderLogo width="160" />
                            <Menu/>
                        </div>
                        <p>{error}</p>
                    </div>
                :
                    <ProfileForm
                        initialValues={initialData}
                        onSubmit={onSubmit}
                        errors={formErrors}
                        success={success}
                        waiting={sending}
                        enableReinitialize={true}
                    />
                }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default Profile;