export const setBaseUrl = "https://charging.bluwave-ai.com/app-api"
export const authDomain = "bwai.us.auth0.com"
export const authClientID = "BEwCPrASXqgja0rmv9tN8geAkRHHiQOx"

export const regionCodes = {
    "CA-AB": {Name: "CA-AB", ID: "10307"},
    "CA-ON": {Name: "CA-ON", ID: "10698"},
    "CA-QC": {Name: "CA-QC", ID: "10308"},
    "AE": {Name: "AE", ID: "9625"},
    "US-CAL-CISO": {Name: "US-CAL-CISO", ID: "10196"},
    "US-FLA-FMPP": {Name: "US-FLA-FMPP", ID: "10316"},
    "US-FLA-FPC": {Name: "US-FLA-FPC", ID: "10317"},
    "US-FLA-FPL": {Name: "US-FLA-FPL", ID: "10318"},
    "US-FLA-GVL": {Name: "US-FLA-GVL", ID: "10319"},
    "US-FLA-HST": {Name: "US-FLA-HST", ID: "10320"},
    "US-FLA-JEA": {Name: "US-FLA-JEA", ID: "10321"},
    "US-FLA-SEC": {Name: "US-FLA-SEC", ID: "10322"},
    "US-FLA-TAL": {Name: "US-FLA-TAL", ID: "10323"},
    "US-FLA-TEC": {Name: "US-FLA-TEC", ID: "10324"},
    "US-TEX-ERCO": {Name: "US-TEX-ERCO", ID: "10277"}
  }

