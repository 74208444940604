
import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import "./Loginform.scss";
import LogoPvg from "../../assets/bluwave-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import {BiShow} from "react-icons/bi";


const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const validate = (values) => {
    const errors = {};
    
    if (!values.password) {
      errors.password = "Password Required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }
    if (!values.password2) {
      errors.password2 = "Password Required";
    } else if(values.password != values.password2) {
      errors.password2 = "Password doesn't match"
    }
  
    return errors;
  };
  
  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
  }) => (
    <div>
      <input {...input} placeholder={label} type={type} className="input" />
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-danger">{warning}</p>))}
    </div>
  );
  
  const ResetPasswordForm = (props) => {
    const onSubmit = (formValues) => {
      props.onSubmit(formValues);
    };

    let [displayPassword, setDisplayPassword] = useState(false);

    const ToggleDisplayPassword = () => {
      setDisplayPassword(!displayPassword);
    }
  
    const { errors, handleSubmit, pristine, submitting, loading } = props;
    return (
      <form className="box" onSubmit={handleSubmit(onSubmit)}>
        <div className="field has-text-centered">
          <br />
          <RenderLogo width="160" />
          <br />
        </div>
        <p className="has-text-centered">Please enter and confirm your new password</p>
        <div className="field input--add_extra_margin">
          <label className="label">Password</label>
          <div className="control has-icons-right">
            <Field
              type={displayPassword ? "text" : "password"}
              name="password"
              component={renderField}
              label="Password"
            />
            <span className="icon is-small is-right" style={{cursor:"pointer", zIndex:"100", pointerEvents:"auto"}} onClick={ToggleDisplayPassword}>
              <BiShow style={{fill:"#000"}}/>
            </span>
          </div>
        </div>
        <div className="field input--add_extra_margin">
          <label className="label">Repeat Password</label>
          <div className="control has-icons-right">
            <Field
              type={displayPassword ? "text" : "password"}
              name="password2"
              component={renderField}
              label="Repeat Password"
            />
            <span className="icon is-small is-right" style={{cursor:"pointer", zIndex:"100", pointerEvents:"auto"}} onClick={ToggleDisplayPassword}>
              <BiShow style={{fill:"#000"}}/>
            </span>
          </div>
        </div>

        <div className="field has-text-centered">
          <p className="help is-danger">{errors}</p>
          <br />
          <button
            className={`button is-primary ${loading ? `is-loading` : ``}`}
            disabled={pristine || submitting}
          >
            Save
          </button>
          <br />
          <br />
        </div>
      </form>
    );
  }
  
  export default reduxForm({
    form: "register",
    validate,
  })(ResetPasswordForm);