import { useRef, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CarForm from "./carForm";
import axios from "../../utils/raxios";
import { AiOutlinePlus } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { AiFillCar } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import { RiSteering2Fill } from "react-icons/ri";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const RenderEnodeIcon = (props) => (
  <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M14.26 6.408c.766 0 1.34.297 1.722.743a8.033 8.033 0 0 0-15.975.054c-.025.25.016.502.12.731a1.761 1.761 0 0 0 3.243-.052.922.922 0 0 0 .06-.286c0-.014 0-.027.004-.04a4.586 4.586 0 0 1 9.072-.364c.381-.467.965-.786 1.754-.786Z" fill="currentColor"></path><path d="M14.435 7.204c-.785-.081-1.538.401-1.825 1.11a.48.48 0 0 0-.029.13 4.584 4.584 0 0 1-9.041.495c-.375.54-.994.915-1.853.915C1 9.854.468 9.612.09 9.24a8.03 8.03 0 0 0 15.908-.348.48.48 0 0 0 0-.103c-.095-.801-.744-1.5-1.562-1.585Z" fill="currentColor"></path><path d="M8.145 9.792a1.865 1.865 0 1 0 0-3.73 1.865 1.865 0 0 0 0 3.73Z" fill="currentColor"></path></svg>
);

const Index = ({car, refetch, transactions}) => {
  
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState("");

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    axios.post(`/car`, data).then((response) => {
      handleClose();
      refetch();
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
      else if(error.response) setErrors("Invalid request")
      else setErrors("No response from server"); 
    });
  };

  const registerSmartcar = () => {
    const data = {carID: car.id}
    axios.post(`/registersmartcar`, data).then((response) => {
      window.location.href = response.data.url;
    }).catch((error) => {
      console.log(error);
      if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
      else if(error.response) setErrors("Invalid request")
      else setErrors("No response from server"); 
    });
  }
  
  const registerEnode = () => {
    const data = {carID: car.id}
    axios.post(`/registerenode`, data).then((response) => {
      window.location.href = response.data.url;
    }).catch((error) => {
      console.log(error);
      if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
      else if(error.response) setErrors("Invalid request")
      else setErrors("No response from server"); 
    });
  }  
  const deregisterSmartcar = () => {
    const data = {carID: car.id}
    axios.post(`/deregistersmartcar`, data).then((response) => {
      handleClose();
      refetch();
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
      else if(error.response) setErrors("Invalid request")
      else setErrors("No response from server"); 
    });
  }

  
  const deregisterEnode = () => {
    const data = {carID: car.id}
    axios.post(`/deregisterenode`, data).then((response) => {
      handleClose();
      refetch();
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
      else if(error.response) setErrors("Invalid request")
      else setErrors("No response from server"); 
    });
  }

  const deregisterSmartcarConfirmation = () => {
    confirmAlert({
      message:`Do you really want to disconnect your car from SmartCar?`,
      buttons: [
        {
          label:"Disconnect",
          onClick: () => deregisterSmartcar() 
        },
        {
          label:"Cancel",
        }
      ]
    });
  }

  
  const deregisterEnodeConfirmation = () => {
    confirmAlert({
      message:`Do you really want to disconnect your car from Enode?`,
      buttons: [
        {
          label:"Disconnect",
          onClick: () => deregisterEnode() 
        },
        {
          label:"Cancel",
        }
      ]
    });
  }

  const registerSmartcarConfirmation = () => {
    confirmAlert({
      message:`Do you really want to connect  your car to SmartCar?`,
      buttons: [
        {
          label:"Connect",
          onClick: () => registerSmartcar() 
        },
        {
          label:"Cancel",
        }
      ]
    });
  }

  
  const registerEnodeConfirmation = () => {
    confirmAlert({
      message:`Do you really want to connect  your car to Enode?`,
      buttons: [
        {
          label:"Connect",
          onClick: () => registerEnode() 
        },
        {
          label:"Cancel",
        }
      ]
    });
  }

  const toggleSmartcarCharging = () => {
    window.location.href="#/smartcar/"+car.id
  }
  const toggleEnodeCharging = () => {
    window.location.href="#/enode/"+car.id
  }

  const deleteConfirmation = () => {
    confirmAlert({
      message:`Are you sure you want to delete the car ${car.brand} ${car.model} ${car.year}?`,
      buttons: [
        {
          label:"Delete Car",
          onClick: () => deleteCar() 
        },
        {
          label:"Cancel",
        }
      ]
    });
  }

  const deleteCar = () => {

    axios.delete(`/car?id=${car.id}`).then((response) => {
        refetch();
      }).catch((error) => {
        
    });
  };


  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const iconStyle = {color:"hsl(229, 53%, 53%)", cursor:"pointer", verticalAlign:"middle", marginLeft:"20px", width:"20px"}
  const addIconStyle = {color:"hsl(229, 53%, 53%)", cursor:"pointer", verticalAlign:"middle", fontSize:"20px", width:"20px"}
  const inactiveIconStyle = {color:"hsl(229, 83%, 83%)", cursor:"pointer", verticalAlign:"middle", marginLeft:"20px", width:"20px"}

  const enodeIconStyle = {color:"hsl(93, 60%, 37%)", cursor:"pointer", verticalAlign:"middle", marginLeft:"20px", width:"20px"}
  const enodeInactiveIconStyle = {color:"hsl(93, 60%, 70%)", cursor:"pointer", verticalAlign:"middle", marginLeft:"20px", width:"20px"}

  const smartcarIconStyle = {color:"hsl(0, 0%, 0%)", cursor:"pointer", verticalAlign:"middle", marginLeft:"20px", width:"20px"}
  const smartcarInactiveIconStyle = {color:"hsl(0, 0%, 70%)", cursor:"pointer", verticalAlign:"middle", marginLeft:"20px", width:"20px"}



  return (
    <>
      {car ? 
        <tr style={{lineHeight:"40px", border:"0px solid"}}>
          <td><AiFillCar/></td>
          <td>
            <span>
              {car.brand} {car.model} {car.year}
            </span>
          </td>
          <td>

            {car.isEnode && <span title="Interact via Enode"><RenderEnodeIcon style={enodeIconStyle} onClick={toggleEnodeCharging}/></span>}
            {!car.isEnode && <span title="Link with Enode"><RenderEnodeIcon style={enodeInactiveIconStyle} onClick={registerEnodeConfirmation}/></span>}

            {car.isSmartcar && <RiSteering2Fill style={smartcarIconStyle} onClick={toggleSmartcarCharging}/>}
            {!car.isSmartcar && <RiSteering2Fill style={smartcarInactiveIconStyle} onClick={registerSmartcarConfirmation}/>}

            <FiEdit2 onClick={handleClickOpen()} style={iconStyle}/>
            {!transactions.find(transaction => !transaction.finishedAt && transaction.carID == car.id) ?
              <>
                <MdDeleteForever onClick={deleteConfirmation} style={iconStyle}/>
              </>
              : <div style={{width:"72px"}}></div>
            }
          </td>
        </tr>
      :
      <div><b>Add your car</b><AiOutlinePlus onClick={handleClickOpen()} style={addIconStyle}/></div>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent>
          <CarForm initialValues={car} onSubmit={onSubmit} registerSmartcar={registerSmartcarConfirmation} registerEnode={registerEnodeConfirmation} deregisterSmartcar={deregisterSmartcarConfirmation} deregisterEnode={deregisterEnodeConfirmation} errors={errors}/>
        </DialogContent>
        <DialogActions>
          <div
            className={`button is-family-secondary has-text-weight-bold is-light`}
            onClick={handleClose}
          >
            Cancel
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Index;
