import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
//components
import Login from "./auth/login";
import Register from "./auth/register";
import VerifyEmail from "./auth/verifyemail";
import ForgotPassword from "./auth/forgotpassword";
import ResetPassword from "./auth/resetpassword";
import Dashboard from "./dashboard/dashboard";
import Profile from "./profile/profile";
import Chargers from "./chargers/chargers";
import ToggleTransaction from "./toggletransaction/toggletransaction";
import Transactions from "./transactions/transactions";
import RegisterSmartcar from "./registersmartcar/registersmartcar";
import ToggleSmartcarCharging from "./smartcarcharging/togglecharging";
import RegisterEnode from "./registerenode/registerenode";
import ToggleEnodeCharging from "./enodecharging/togglecharging";
import EnergyMix from "./energymix/energymix";
import BluscoreForecast from "./bluscoreforecast/bluscoreforecast";
import { RedirectAs404 } from "./404";
import { authDomain, authClientID } from "../utils/config";


import { QueryClient, QueryClientProvider } from "react-query";
import store, { persistor } from "../redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import {Auth0Provider } from "@auth0/auth0-react"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 1, //1 hour
    },
  },
});


function App() {
  let redirectURI = window.location.origin+window.location.pathname+"#/login"
  return (
    <Auth0Provider domain={authDomain} clientId={authClientID} authorizationParams={{redirect_uri: redirectURI}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router basename="/">
                <Routes>
                  <Route exact path="/register" element={<Register/>} />
                  <Route exact path="/verifyemail/:email/:token" element={<VerifyEmail/>} />
                  <Route exact path="/forgotpassword" element={<ForgotPassword/>} />
                  <Route exact path="/resetpassword/:email/:token" element={<ResetPassword/>} />
                  <Route exact path="/dashboard" element={<Dashboard/>} />
                  <Route exact path="/profile" element={<Profile/>} />
                  <Route exact path="/chargers" element={<Chargers/>} />
                  <Route exact path="/charger/:id" element={<ToggleTransaction/>} />
                  <Route exact path="/transactions" element={<Transactions/>} />
                  <Route exact path="/registersmartcar" element={<RegisterSmartcar/>} />
                  <Route exact path="/smartcar/:id" element={<ToggleSmartcarCharging/>} />
                  <Route exact path="/registerenode" element={<RegisterEnode/>} />
                  <Route exact path="/enode/:id" element={<ToggleEnodeCharging/>} />
                  <Route exact path="/login" element={<Login/>} />
                  <Route exact path="/energymix" element={<EnergyMix/>} />
                  <Route exact path="/bluscoreforecast" element={<BluscoreForecast/>} />
                  <Route component={RedirectAs404} />
                </Routes>
              </Router>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </LocalizationProvider>
    </Auth0Provider>
  );
}

export default App;
