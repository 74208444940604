
import axios from "../../utils/raxios";
import LoginForm from "./loginform";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchTransactionData} from "../../utils/queries";
import { useQueryClient } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { authDomain, authClientID } from "../../utils/config";

const Login = (props) => {

  const { isLoading, isAuthenticated, error:authError, getAccessTokenSilently, user, loginWithRedirect, logout } = useAuth0();

  const queryClient = useQueryClient();
  const [errors, setErrors] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);


  const { data, isLoading:isLoadingTransactions, refetch } = useQuery(
    "fetchTransactionData",
    fetchTransactionData,
    {onError: (error) => {
    }, refetchOnWindowFocus:false, enabled: false},
  );

  useEffect(() => {
    queryClient.clear();
    queryClient.invalidateQueries();
  }, []);

  
  useEffect(() => {
    if(!isLoadingTransactions && loggedIn) {
      const t = data.find((element) => {
        return !element.finishedAt;
      });
      if(t !== undefined) {
        window.location.href="#/charger/"+t.chargerID;
      } else {
        window.location.href="#/dashboard";
      }
    }
  }, [data, isLoadingTransactions, loggedIn]);


  useEffect(() => {

    const sendAccessToken = async () => {

      const accessToken = await getAccessTokenSilently(
        {authorizationParams:{
          scope:"profile openid email",
        },}
      );
      
      axios.post("/login", {accessToken: accessToken}).then((response) => {
        setLoggedIn(true);
        refetch();
      }).catch((error) => {
        if(error.response && error.response.data && error.response.data.description) setErrors(error.response.data.description);
        else if(error.response) setErrors("Invalid request")
        else setErrors("No response from server");
      });

    }
    if(isAuthenticated) sendAccessToken();
  }, [isAuthenticated]);
  


  useEffect(() => {
    if(isLoading || isAuthenticated) return;
    loginWithRedirect();
  }, [isLoading, isAuthenticated]);




  
  if(authError || errors) {
    return (
      <section className="hero is-primary is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                <div className={"box has-text-centered"}>
                    <div className="help is-danger" style={{margin:"20px"}}>{authError? authError : errors}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
            </div>
          </div>
        </div>
      </div>
    </section>
  );

};




export default Login;
