
import { useEffect } from "react";
import axios from "../../utils/raxios";
import { useState } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import {fetchChargerData, fetchUserData, fetchTransactionData} from "../../utils/queries";
import StartCharging from "./startcharging";
import StopCharging from "./stopcharging";
import LoadIcon from "../../assets/loading-icon-animated-gif-9.jpg";
import LogoPvg from "../../assets/bluwave-logo.png";



import { useQuery, useQueryClient } from "react-query";

import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const RenderLoadIcon = (props) => (
  <img {...props} alt="Loadin" src={[LoadIcon]} />
);

const RenderEnodeIcon = (props) => (
  <svg {...props} class="_1f4lbxf7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" fill="#599926" height="43" width="70"><path d="M14.4 9.89c.774 0 1.353.3 1.74.75a8.112 8.112 0 0 0-16.133.054c-.025.253.016.508.121.74a1.779 1.779 0 0 0 3.275-.053.93.93 0 0 0 .062-.29c0-.013 0-.027.003-.04a4.631 4.631 0 0 1 9.161-.367c.386-.472.975-.794 1.772-.794Z"></path><path d="M14.578 10.695c-.793-.083-1.554.404-1.843 1.12a.487.487 0 0 0-.03.132 4.63 4.63 0 0 1-9.13.5c-.379.544-1.004.923-1.871.923-.695 0-1.231-.244-1.614-.62a8.11 8.11 0 0 0 16.065-.351.477.477 0 0 0 0-.104c-.096-.81-.752-1.516-1.577-1.6Z"></path><path d="M8.224 13.307a1.883 1.883 0 1 0 0-3.766 1.883 1.883 0 0 0 0 3.766ZM31.838"></path></svg>
);

const enodeIconStyle = {color:"hsl(93, 60%, 37%)", cursor:"pointer", verticalAlign:"middle", marginLeft:"20px", width:"100px"}

const EnodeCharging = (props) => {

  let { id } = useParams();
  let [ error, setError ] = useState("");
  let [sendingRequest, setSendingRequest] = useState(false);

  let [SOC, setSOC] = useState(0);
  let [pluggedIn, setPluggedIn] = useState(false);
  let [isCharging, setIsCharging] = useState(false);
  let [gotChargingData, setGotChargingData] = useState(false);
  let [car, setCar] = useState({});


  const { data, isLoading } = useQuery(
    "fetchUserData",
    fetchUserData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  
  const onStartCharging = () => {
    setSendingRequest(true);
    const data = {carID: parseInt(id)}
    axios.post("/startenodetransaction", data).then((response) => {
      setSendingRequest(false);
      setIsCharging(true);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server");
      setSendingRequest(false);
    });
  };

  const onStopCharging = () => {
    setSendingRequest(true);
    const data = {carID: parseInt(id)}
    axios.post("/stopenodetransaction", data).then((response) => {
      setSendingRequest(false);
      setIsCharging(false);
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server");
      setSendingRequest(false);
    });
  };


  useEffect(() => {
    axios.get("/enodechargingstatus?car="+encodeURIComponent(id)).then((response) => {
      setSOC(response.data.soc);
      setIsCharging(response.data.charging);
      setPluggedIn(response.data.pluggedIn);
      setGotChargingData(true);
    }).catch((error) => {
          console.log(error);
          if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
          else if(error.response) setError("Invalid request")
          else setError("No response from server"); 
    });
  }, []);


  
  useEffect(() => {
    if(data && Array.isArray(data.cars)) {
      const cars = data.cars;
      let foundCar = cars.find(car => car.id == id);
      if (!foundCar) {
        setError("No car by that ID");
      } else {
        setCar(foundCar);
      }
    }
  }, [data, isLoading]);

  if(!gotChargingData || isLoading) {
    if(error) {
      return (
        <section className="hero is-primary is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                  <div className={"box has-text-centered"}>
                      <div style={{justifyContent:"space-between", display:"flex"}}>
                          <HomeIcon/>
                          <RenderLogo width="160" />
                          <Menu/>
                      </div>
                      <div className="help is-danger" style={{margin:"20px"}}>{error}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return (
      <section className="hero is-primary is-fullheight">
        <div className="hero-body">
          <div className="container">
              <div className="columns is-centered">
                <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                  <div className={"box has-text-centered"}>
                      <div style={{justifyContent:"space-between", display:"flex"}}>
                          <HomeIcon/>
                          <RenderLogo width="160" />
                          <Menu/>
                      </div>
                      <br/>
                      <RenderEnodeIcon width="100" style={enodeIconStyle}/>
                      <br/>
                      <br/>
                      <div>Be patient ... </div>
                      <RenderLoadIcon width="100" />
                      <div>we are retrieving the charging status of your car ...</div>
                      <br/>
                      <br/>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
              {isCharging ? 
              <StopCharging SOC={SOC} car={car} onSubmit={onStopCharging} waiting={sendingRequest} error={error}/> 
              : <StartCharging  SOC={SOC} car={car} onSubmit={onStartCharging} waiting={sendingRequest} pluggedIn={pluggedIn} error={error}/>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default EnodeCharging;
