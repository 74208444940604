
import { useEffect } from "react";
import axios from "../../utils/raxios";
import { useState } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import {fetchChargerData} from "../../utils/queries";
import ChargerTable from "../../components/chargertable/chargerTable";
import LogoPvg from "../../assets/bluwave-logo.png";

import { useQuery, useQueryClient } from "react-query";

const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);


const Chargers = (props) => {

  let [ error, setError ] = useState("");

  const { data, isLoading } = useQuery(
    "fetchChargerData",
    fetchChargerData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  if(isLoading) return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen"></div>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                {error ?
                    <div className={"box has-text-centered"}>
                        <div>{error}</div>
                    </div>
                    :
                    <div className={"box has-text-centered"}>
                        <div style={{justifyContent:"space-between", display:"flex"}}>
                          <HomeIcon/>
                          <RenderLogo width="160" />
                          <Menu/>
                        </div>
                        <div style={{lineHeight:"50px", marginTop:"8px"}}><b>Chargers</b></div>
                        <ChargerTable data={data} />
                    </div>
                }

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default Chargers;
