
import axios from "./raxios";


export const fetchUserData = async () => {
    
    const { data } = await axios.get("/userdata");
    return data;

}

export const fetchChargerData = async () => {
    
    const { data } = await axios.get("/chargers");
    return data;

}
export const fetchTransactionData = async () => {
    
    const { data } = await axios.get("/transactions");
    return data;

}


export const fetchBluscore = async (region) => {
    
    const { data } = await axios.get("/bluscore?region="+region);
    return data;

}

export const fetchLocationData = async () => {

    if(!navigator.geolocation) return "Geolocation not supported by browser"

    var position = await getCoordinates();

    return getRegion(position.coords)

}

function getCoordinates() {
    return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
    });
}


const getRegion = async (coords) => {

    const { data } = await axios.get("/region?lat="+coords.latitude+"&lon="+coords.longitude);
    return data?.code;
}

/*function getRegion(coords) {
    try {
        var url = "https://nominatim.openstreetmap.org/reverse?lat="+coords.latitude+"&lon="+coords.longitude

        var request = new XMLHttpRequest();
        request.open("GET", url, false);
        request.send(null);


        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(request.responseText, "text/xml");

        return xmlDoc.getElementsByTagName("ISO3166-2-lvl4")[0].childNodes[0].nodeValue;
    }
    catch(err) {
        return "";
    }

}*/


