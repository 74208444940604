
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import Menu from "../../components/menu";
import HomeIcon from "../../components/homeicon";
import LogoPvg from "../../assets/bluwave-logo.png";
import {fetchChargerData, fetchUserData, fetchTransactionData} from "../../utils/queries";
import TransactionTable from "../../components/transactionTable/transactiontable";
import SelectedTransaction from "../../components/transactionTable/selectedTransaction";
import DateRangePicker from "react-bootstrap-daterangepicker";

import { useQuery, useQueryClient } from "react-query";

const RenderLogo = (props) => (
  <img {...props} alt="Bluewave Logo" src={[LogoPvg]} />
);

const TimeRangeSelector = ({timeRange, setTimeRange}) => {


  const [selectedOption, setSelectedOption] = useState("All");
  const { start, end } = timeRange;
  
  const handleCallback = (start, end) => {
    setTimeRange({ start, end, time_range_id: 1 });
  };

  
  useEffect(() => {
    if (selectedOption === "All") {
      setTimeRange({
        start: undefined,
        end: undefined,
      });
    }
    else if (selectedOption === "Last 7 days") {
      setTimeRange({
        start: moment().subtract(7, "days"),
        end: undefined,
      });
    }
    else if (selectedOption === "Last 31 days") {
      setTimeRange({
        start: moment().subtract(31, "days"),
        end: undefined,
      });
    }
    else if (selectedOption === "Custom") {
      setTimeRange({
        start: moment().subtract(7, "days"),
        end: moment().add(1, "days"),
      });
    }
  }, [selectedOption, setSelectedOption, setTimeRange]);


  return (
    <>
      <div className="select is-small">
        <select
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e.target.value);
          }}
        >
          <option value={"All"}>
           Entire Time
          </option>
          <option value={"Last 7 days"}>
            Last 7 days
          </option>
          <option value={"Last 31 days"}>
            Last 31 days
          </option>
          <option value={"Custom"}>
            Custom Time Range
          </option>
        </select>
      </div>
      &nbsp;
      {selectedOption === "Custom" && start && end && (
        <DateRangePicker
          initialSettings={{
            startDate: start.toDate(),
            endDate: end.toDate(),
          }}
          onCallback={handleCallback}
        >
          <button className="button is-link is-small">{start.format("DD-MM-YY") + " - " + end.format("DD-MM-YY")}</button>
        </DateRangePicker>
      )}
    </>
  );


}


const Index = (props) => {

  let [ error, setError ] = useState("");
  let [transaction, setCurrentTransaction ] = useState(false);
  let [filteredTransactions, setFilteredTransactions ] = useState([]);
  let [totalEnergy, setTotalEnergy ] = useState(0);


  const [timeRange, setTimeRange] = useState({
    start: moment().subtract(6, "days"),
    end: moment().add(1, "days"),
  });

  const { start, end } = timeRange;

  const backButton = () => {
    setCurrentTransaction(false);
  }

  const { data:userdata, isLoading:isLoadingUser } = useQuery(
    "fetchUserData",
    fetchUserData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  const { data:transactiondata, isLoading:isLoadingTransaction } = useQuery(
    "fetchTransactionData",
    fetchTransactionData,
    {onError: (error) => {
      if(error.response && error.response.data && error.response.data.description) setError(error.response.data.description);
      else if(error.response) setError("Invalid request")
      else setError("No response from server"); 
    }},
  );

  useEffect(() => {
    if(isLoadingTransaction) return;
    let totalEnergy = 0;
    setFilteredTransactions(transactiondata.filter((transaction) => {
      let time = new Date(transaction.startedAt.replace(" ", "T"));
      if((!start || time >= start) && (!end || time <= end)) {
        totalEnergy += transaction.energyCharged;
        return true;
      }
    }));
    setTotalEnergy(totalEnergy);
  }, [transactiondata, isLoadingTransaction, start, end, setFilteredTransactions, setTotalEnergy]);




  if(isLoadingUser || isLoadingTransaction) return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen"></div>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-6-desktop is-4-widescreen">
                {error ?
                    <div className={"box has-text-centered"}>
                        <div style={{justifyContent:"space-between", display:"flex"}}>
                            <HomeIcon/>
                            <RenderLogo width="160" />
                            <Menu/>
                        </div>
                        <div>{error}</div>
                    </div>
                    :
                      <div className="box has-text-centered">
                        <div style={{justifyContent:"space-between", display:"flex", lineHeight:"56px", fontSize:"18px"}}>
                            <HomeIcon/>
                            <RenderLogo width="160" />
                            <Menu/>
                        </div>
                        <div>{transaction && <span><b>Charging Session Summary</b></span>}</div>
                        {transaction ?
                          <>
                            <SelectedTransaction transaction={transaction} cars={userdata.cars} backButton={backButton}/>
                            <TransactionTable key="table" data={filteredTransactions} setCurrentTransaction={setCurrentTransaction} hide={true}/>
                          </>
                        :
                        <>
                          <div style={{border:"0px solid", lineHeight:"40px", marginTop:"0px"}}><b>My Charging Sessions over</b></div>
                          <TimeRangeSelector timeRange={timeRange} setTimeRange={setTimeRange}/>
                          <div style={{marginTop:"10px", marginBottom:"10px"}}><b>Total Energy Charged: {totalEnergy?.toFixed(2)} kWh</b></div>
                          <TransactionTable key="table" data={filteredTransactions} setCurrentTransaction={setCurrentTransaction}/>
                        </>
                        }
                      </div>
                }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};




export default Index;
